import React from 'react'
import { useAtomValue } from 'jotai'
import { FragmentMetadata, fragmentMetdata } from '../../store/stakzStore'
import ReactMarkdown from 'react-markdown'
import StakzWidget from '../stakzWidget/layout'
import Widget from '../widget/layout'
import CodeWidget from 'components/codeWidget'

export default function StakzView(props: {}) {
  const fragments: FragmentMetadata[] = useAtomValue(fragmentMetdata)

  function getFragmentForMetadata(
    metadata: FragmentMetadata,
    key: string | number,
  ): JSX.Element {
    switch (metadata.fragmentType) {
      case 'md':
        return (
          <ReactMarkdown className='prose prose-invert' key={key}>
            {metadata.content}
          </ReactMarkdown>
        )
      case 'stakz':
        return (
          <StakzWidget
            /* key={currentContent}  */ key={key}
            stakFile={metadata.content}
          />
        )
      case 'widget':
        return <Widget key={`widget ${key}`} content={metadata.content} />
      case 'code':
        return <CodeWidget key={`code ${key}`} content={metadata.content} />
    }
  }

  return (
    <div className='overflow-y-scroll h-full w-full flex-1'>
      <div className='max-w-screen-lg mx-auto flex flex-col p-2 lg:p-16 md:p-4 '>
        {fragments.map((e, index) => (
          <div key={index} style={{ marginBottom: '0.5em' }}>
            {getFragmentForMetadata(e, index)}
          </div>
        ))}
      </div>
    </div>
  )
}
