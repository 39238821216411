import { Textarea } from '@mantine/core'
import React, { useState, useEffect } from 'react'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import { isLoadingPrompt } from 'store/fileStore'
import {
  selectedModels as selectedModelsAtom,
  currentPrompt as currentPromptAtom,
  runPrompt as runPromptAtom,
} from 'store/promptStore'
import { FaPlay, FaChevronDown, FaChevronUp, FaCog } from 'react-icons/fa'

export default function PromptEngineeringView() {
  const [currentPrompt, setCurrentPrompt] = useAtom(currentPromptAtom)
  const [systemPrompt, setSystemPrompt] = useState('')
  const loading = useAtomValue(isLoadingPrompt)
  const selectedModels = useAtomValue(selectedModelsAtom)
  const [showSystemPrompt, setShowSystemPrompt] = useState(false)
  const [isCollapsed, setIsCollapsed] = useState(true)
  const [selectedWidgets, setSelectedWidgets] = useState<WidgetType[]>([])
  const runPrompt = useSetAtom(runPromptAtom)
  const firstLine = systemPrompt.split('\n')[0]

  type WidgetType = '__all__' | 'code' | 'yaml' | 'json' | 'script'
  const widgetOptions: { value: WidgetType; label: string }[] = [
    { value: '__all__', label: 'Select All' },
    { value: 'code', label: 'Code' },
    { value: 'yaml', label: 'YAML' },
    { value: 'json', label: 'JSON' },
    { value: 'script', label: 'Script' },
  ]

  const handleWidgetChange = (values: WidgetType[]) => {
    if (values.includes('__all__')) {
      // If "Select All" was just selected
      if (selectedWidgets.length !== widgetOptions.length - 1) {
        setSelectedWidgets(widgetOptions.slice(1).map(opt => opt.value))
      } else {
        // If "Select All" was just deselected
        setSelectedWidgets([])
      }
    } else {
      setSelectedWidgets(values)
    }
  }

  useEffect(() => {
    const handleKeyDown = async (e: KeyboardEvent) => {
      if (e.ctrlKey && e.key === 'g') {
        e.preventDefault()
        runPrompt()
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [runPrompt])

  return (
    <div className='size-full flex flex-col bg-neutral-800 text-white p-2'>
      <div className='flex flex-1 min-h-0'>
        {/* <div className='flex flex-col bg-neutral-700 p-1 mr-2 rounded-md'>
          <button
            onClick={() => setShowSystemPrompt(!showSystemPrompt)}
            className='p-2 hover:bg-neutral-600 rounded-md transition-colors'
            title={showSystemPrompt ? 'Hide system prompt' : 'Show system prompt'}
          >
            <FaCog size={14} className={showSystemPrompt ? 'text-blue-400' : 'text-neutral-400'} />
          </button>
        </div> */}
        <div className='flex flex-col flex-1 min-h-0'>
          {showSystemPrompt && (
            <div className='flex flex-col flex-shrink-0 mb-2 p-2 bg-neutral-700 rounded-md shadow-lg'>
              <div className='flex justify-between items-center mb-2'>
                <div className='flex items-center gap-2'>
                  <button
                    onClick={() => setIsCollapsed(!isCollapsed)}
                    className='p-2 hover:bg-neutral-600 rounded-md transition-colors'
                  >
                    {isCollapsed ? (
                      <FaChevronDown size={16} />
                    ) : (
                      <FaChevronUp size={16} />
                    )}
                  </button>
                  <div>
                    <h4 className='text-sm text-neutral-400 m-0'>
                      System Prompt
                    </h4>
                    <p className='text-sm m-0 truncate max-w-[300px]'>
                      {firstLine}
                    </p>
                  </div>
                </div>
                {/* <div className='flex items-center gap-2'>
                  <MultiSelect
                    value={selectedWidgets}
                    onChange={handleWidgetChange}
                    data={widgetOptions}
                    placeholder="Select widgets"
                    styles={{
                      input: {
                        backgroundColor: '#262626',
                        border: '1px solid #404040',
                        color: 'white',
                        minWidth: '200px',
                        width: ``,
                        transition: 'width 0.2s ease',
                        '&:focus': {
                          borderColor: '#3b82f6'
                        }
                      },
                      dropdown: {
                        backgroundColor: '#262626',
                        border: '1px solid #404040'
                      }
                    }}
                  />
                </div> */}
              </div>
              <div className='flex-1 min-h-0'>
                <Textarea
                  className='w-full h-full bg-neutral-800 rounded-md'
                  styles={{
                    input: {
                      height: '100%',
                      backgroundColor: '#262626',
                      border: '1px solid #404040',
                      color: 'white',
                      padding: '0.75rem',
                      '&:focus': {
                        borderColor: '#3b82f6',
                      },
                    },
                    wrapper: { height: '100%', width: '100%' },
                  }}
                  onChange={e => setSystemPrompt(e.target.value)}
                  value={systemPrompt}
                  placeholder='system prompt'
                  disabled={loading}
                />
              </div>
            </div>
          )}
          <div className='flex flex-col flex-1 min-h-0'>
            <h4 className='text-sm text-neutral-400 m-0 mb-1'>Prompt</h4>
            <Textarea
              className='flex-1 min-h-0'
              styles={{
                input: {
                  height: '100%',
                  resize: 'none',
                  width: '100%',
                  backgroundColor: '#262626',
                  border: '1px solid #404040',
                  color: 'white',
                  '&:focus': {
                    borderColor: '#3b82f6',
                  },
                },
                wrapper: { height: '100%', width: '100%' },
              }}
              onChange={e => setCurrentPrompt(e.target.value)}
              value={currentPrompt}
              placeholder='Enter your prompt here... (ctrl + g to run)'
              disabled={loading}
            />
          </div>
          <div className='flex justify-start mt-2'>
            <button
              onClick={runPrompt}
              disabled={
                loading || !currentPrompt.trim() || selectedModels.length === 0
              }
              className='bg-blue-500 p-2 rounded-md border-none hover:bg-blue-600 transition-colors disabled:opacity-50 disabled:cursor-not-allowed'
            >
              <FaPlay size={16} />
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
