import React, { useEffect, useMemo, useState } from 'react'

import AceEditor from './ace-editor'
import TerminalView from './terminal/terminal'
import type { CommandItem as CommandItemType } from './terminal/terminal'
import { CommandItem } from './terminal/terminal'
import {
  CommandHistoryEntry,
  executeScript as executeScriptAtom,
} from 'store/commandStore'
import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai'
import { activeBottomView, dataValuesObj } from 'store/stakzStore'

interface CodeWidgetProps {
  content: string
}

const fileNameRegex = /```(\w[^\n]*)\n/

const CodeWidget: React.FC<CodeWidgetProps> = ({ content }) => {
  const dataValuesObject = useAtomValue(dataValuesObj)
  const interpolateDataValue = (content: string) => {
    const interpolationRegex = /\${([\w.]+)}/g
    return content.replaceAll(interpolationRegex, (_, p1) => {
      const keys = p1.split('.')
      let value: any = dataValuesObject
      for (const key of keys) {
        value = value[key]
        if (!value) {
          return ''
        }
      }
      if (typeof value === 'object') {
        return JSON.stringify(value)
      } else {
        return String(value)
      }
    })
  }

  const [renderContent, setRenderContent] = useState(() => {
    // Initialize renderContent with processed content
    const filenameMatch = content.match(fileNameRegex)
    const fileName = filenameMatch ? filenameMatch[1] : undefined
    const mode = getModeFromFilename(fileName)
    let processedContent = content
      .replace(fileNameRegex, '')
      .replace(/```/g, '')
      .trim()
    if (mode === 'yaml' || mode === 'json' || mode === 'sh') {
      processedContent = interpolateDataValue(processedContent)
    }
    return processedContent
  })
  const [mode, setMode] = useState(() => {
    const filenameMatch = content.match(fileNameRegex)
    const fileName = filenameMatch ? filenameMatch[1] : undefined
    return getModeFromFilename(fileName)
  })
  const [fileName, setFileName] = useState(() => {
    const filenameMatch = content.match(fileNameRegex)
    return filenameMatch ? filenameMatch[1] : undefined
  })
  const commandAtom = useMemo(
    () =>
      atom<CommandHistoryEntry>({
        command: content,
        output: '',
        status: 'not-started',
      }),
    [content],
  )
  const setCommandAtom = useSetAtom(commandAtom)

  function getModeFromFilename(filename?: string) {
    if (!filename) return 'text'
    const extension = filename.split('.').pop()
    switch (extension) {
      case 'js':
      case 'javascript':
        return 'javascript'
      case 'py':
      case 'python':
        return 'python'
      case 'java':
        return 'java'
      case 'ts':
      case 'typescript':
        return 'typescript'
      case 'sh':
      case 'bash':
        return 'sh'
      case 'md':
        return 'markdown'
      case 'java':
        return 'java'
      case 'yaml':
        return 'yaml'
      case 'json':
        return 'json'
      default:
        return 'text'
    }
  }

  function setNewContent(newContent: string) {
    const newMode = getModeFromFilename(fileName)
    let newRenderContent = newContent
      .replace(fileNameRegex, '')
      .replace(/```/g, '')
      .trim()
    if (newMode == 'yaml' || newMode == 'json' || newMode == 'sh') {
      newRenderContent = interpolateDataValue(newRenderContent)
    }
    setMode(newMode)
    setRenderContent(newRenderContent)
    setCommandAtom(prev => ({ ...prev, command: newRenderContent }))
  }

  useEffect(() => {
    setNewContent(content)
    const filenameMatch = content.match(fileNameRegex)
    const fileName = filenameMatch ? filenameMatch[1] : undefined
    setFileName(fileName)
  }, [content])

  useEffect(() => {
    setNewContent(content)
  }, [dataValuesObject])

  return (
    <AceEditor
      mode={mode}
      theme='nord_dark'
      name='code-editor'
      value={renderContent}
      minLines={2}
      fileName={fileName}
      maxLines={60}
      editorProps={{ $blockScrolling: true }}
      setOptions={{
        useWorker: false,
      }}
      onChange={setRenderContent}
      highlightActiveLine={false}
      className={'bg-neutral-800 border-none'}
      outerClassName={'bg-neutral-800 border-none'}
      keyboardHandler='vim'
      height='100%'
      width='100%'
      wrapEnabled={true}
      enableLiveAutocompletion={true}
    />
  )
}

export default CodeWidget
