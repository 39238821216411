// Create a new component AISubBar.tsx
'use client'
import { useAtom, useAtomValue } from 'jotai'
import {
  currentFile as currentFileAtom,
  openFiles as openFilesAtom,
} from '../../store/fileStore'
import { AIFile, File } from '../api/fileService'

interface AISubBarProps {
  promptId: string
}

export function AISubBar({ promptId }: AISubBarProps) {
  const [currentFile, setCurrentFile] = useAtom(currentFileAtom)
  const openFiles = useAtomValue(openFilesAtom)

  const currentOpenAIFiles = openFiles
    .filter((m: File): m is AIFile => m.type === 'ai')
    .filter(m => m.promptId === promptId)

  return (
    <div className='flex justify-center items-center bg-neutral-800 p-1 border-t border-gray-700'>
      {currentOpenAIFiles.map(model => (
        <button
          key={model.uiName}
          className={`text-gray-300 hover:text-white py-2 px-3 rounded m-1 justify-center items-center  text-sm ${
            model === currentFile ? 'bg-neutral-700' : 'hover:bg-neutral-600'
          }`}
          onClick={() => setCurrentFile(model)}
        >
          {model.uiName}
        </button>
      ))}
    </div>
  )
}
