// @ts-nocheck
'use client'
import React, { useEffect, useRef } from 'react'
import { Select, TextInput } from '@mantine/core'
import dynamic from 'next/dynamic'
import { parseHtml } from '../../service/stakzParser'
import { dataValues, dataValuesObj } from 'store/stakzStore'
import { useAtom, useAtomValue } from 'jotai'
import ScriptWidget from '../scriptWidget/layout'
import yaml from 'js-yaml'

const AceEditor = dynamic(() => import('components/ace-editor'), {
  ssr: false,
})

// Make a string interpolation regex to interpolate ${} strings
const interpolationRegex = /\${(.*?)}/g

function Widget({ node }: { node: Node }): JSX.Element {
  const widgetVar =
    (node as Element).getAttribute('var') ??
    (node as Element).getAttribute('label')
  const [dataValue, setDataValue] = useAtom(dataValues(widgetVar))
  const dataValuesObject = useAtomValue(dataValuesObj)
  const textDataValue = (type: 'json' | 'yaml') => {
    if (typeof dataValue == 'string') {
      return dataValue
    } else if (dataValue == undefined) {
      return ''
    } else if (type == 'json') {
      return JSON.stringify(dataValue, null, 2)
    } else {
      return yaml.dump(dataValue)
    }
  }
  const interpolateDataValue = (content: string) => {
    return content.replaceAll(interpolationRegex, (match, p1) => {
      const keys = p1.split('.')
      let value = dataValuesObject
      for (const key of keys) {
        value = value[key]
        if (!value) {
          return undefined
        }
      }
      return value
    })
  }

  const iframeRef = useRef<HTMLIFrameElement>(null)

  useEffect(() => {
    if (iframeRef.current) {
      iframeRef.current.contentWindow?.postMessage(
        new XMLSerializer().serializeToString(node),
        '*',
      )
    }
  }, [node])

  const iframeContent = `
    <!DOCTYPE html>
    <html>
      <head>
        <style>
          body { margin: 0; }
        </style>
      </head>
      <body>
        <script>
          window.addEventListener('message', (event) => {
            document.body.innerHTML = event.data;
          }, false);
        </script>
      </body>
    </html>
  `

  switch (node.nodeName) {
    case 'YAML':
    case 'JSON':
      return (
        <div key={widgetVar}>
          <div
            className='mantine-InputWrapper-label mantine-TextInput-label'
            style={{
              display: 'inline-block',
              fontSize: '14px',
              fontWeight: 500,
              color: '#C1C2C5',
              wordBreak: 'break-word',
              cursor: 'default',
              WebkitTapHighlightColor: 'transparent',
              marginBottom: '0rem',
            }}
          >
            {node.getAttribute('label')}
          </div>
          <div
            className='mantine-Text-root mantine-InputWrapper-description mantine-TextInput-description'
            style={{
              fontFamily:
                '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji',
              WebkitTapHighlightColor: 'transparent',
              wordBreak: 'break-word',
              color: '#909296',
              fontSize: '12px',
              lineHeight: 1.2,
              display: 'block',
              marginBottom: '0.5rem',
            }}
          >
            {node.getAttribute('description')}
          </div>
          <AceEditor
            highlightActiveLine={false}
            className={'bg-neutral-800 border-none'}
            keyboardHandler='vim'
            style={{
              flex: 1,
              padding: '4em',
              minWidth: '0',
              width: '100%',
              top: 0,
              left: 0,
              right: 0,
            }}
            height='100%'
            maxLines={40}
            wrapEnabled={true}
            mode={node.nodeName == 'YAML' ? 'yaml' : 'json'}
            value={interpolateDataValue(
              textDataValue(node.nodeName == 'YAML' ? 'yaml' : 'json'),
            )}
            onChange={(content, _) => {
              try {
                const contentObj =
                  node.nodeName == 'YAML'
                    ? yaml.load(content)
                    : JSON.parse(content)
                if (contentObj) {
                  setDataValue(contentObj)
                }
              } catch (e) {
                console.log(e)
              }
            }}
            theme='nord_dark'
            setOptions={{
              enableBasicAutocompletion: false,
            }}
            enableLiveAutocompletion={true}
            name='UNIQUE_ID_OF_DIV'
            editorProps={{
              $blockScrolling: true,
            }}
          />
        </div>
      )
    case 'FIELD':
      return (
        <TextInput
          styles={{
            label: { marginBottom: '0rem' },
          }}
          key={widgetVar}
          label={node.getAttribute('label') ?? '<add label attribute>'}
          description={
            node.getAttribute('description') ?? '<add description attribute>'
          }
          onChange={e => {
            setDataValue(e.currentTarget.value)
          }}
          value={dataValue}
          placeholder={
            node.getAttribute('placeholder') ?? '<add placeholder attribute>'
          }
          defaultValue={node.textContent?.trim() ?? ''}
        />
      )
    case 'SELECT':
      const options = node.getAttribute('options')?.split(',') ?? []
      return (
        <Select
          key={widgetVar}
          description={node.getAttribute('description') ?? ''}
          label={node.getAttribute('label') ?? '<add label attribute>'}
          data={options}
          defaultValue={options[0]}
          value={dataValue}
          onChange={v => {
            v && setDataValue(v)
          }}
        ></Select>
      )
    case 'SCRIPT':
      const content = node.textContent?.trim() ?? ''
      return <ScriptWidget script={content} />
    default:
      return (
        <iframe
          ref={iframeRef}
          style={{ width: '100%', height: '100%' }}
          srcDoc={interpolateDataValue(iframeContent)}
          onLoad={() => {
            iframeRef.current?.contentWindow?.postMessage(
              new XMLSerializer().serializeToString(node),
              '*',
            )
          }}
        />
      )
  }
}

export default function Widgets(props: { content: string }) {
  const nodes: Array<Node & { text: string }> = parseHtml(props.content)

  return (
    <>
      {nodes
        .filter(node => node.getAttribute != undefined)
        .map((node, index) => (
          <Widget key={node.nodeName + index.toString()} node={node} />
        ))}
    </>
  )
}
