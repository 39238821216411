'use client'
import React, { useState, useRef, useEffect } from 'react'
import { useAtom, useAtomValue } from 'jotai'
import { FaTerminal, FaBrain } from 'react-icons/fa' // Import icons from react-icons
import { CiCircleAlert } from 'react-icons/ci'
import {
  activeBottomView,
  stakzServerHealthy as stakzServerHealthyAtom,
  token as tokenAtom,
} from 'store/stakzStore'

import { selectedModels as selectedModelsAtom } from 'store/promptStore'

import { modelConfigs } from 'app/api/types/types'

import SplitViewSwitch from 'components/splitviewSwitch'
import PromptEngineeringView from '../../components/terminal/promptEngineering'
import ServerUnhealthy from 'components/serverUnhealthy'
import { TextInput } from '@mantine/core'
import Terminal from './terminal'
import ConfigSelector from 'components/configSelect'
import { ModelSelector } from 'components/modelSelector'

export default function TerminalWrapper() {
  const stakzServerHealthy = useAtomValue(stakzServerHealthyAtom)

  const [token, setToken] = useAtom(tokenAtom)
  const [isToolTipVisible, setIsTooltipVisible] = useState(false)
  const textRef = useRef<HTMLPreElement>(null)
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 })

  const handleTokenChange = (e: any) => {
    setToken(e.target.value)
  }

  const panelRef = useRef<HTMLDivElement>(null)

  const [activeTab, setActiveTab] = useAtom(activeBottomView)
  const [selectedModels, setSelectedModels] = useAtom(selectedModelsAtom) // Default selected models

  useEffect(() => {
    if (isToolTipVisible && textRef.current) {
      const rect = textRef.current.getBoundingClientRect()
      setTooltipPosition({
        top: rect.bottom - rect.top, // Position just below the text
        left: rect.left - rect.left, // Align with the left of the text
      })
    }
  }, [isToolTipVisible])

  return (
    <div
      className='size-full flex-1 pl-4 pr-4 flex flex-col z-10 overflow-hidden'
      ref={panelRef}
    >
      <div className='z-10 sticky flex-0'>
        <div className='flex items-center justify-between p-2'>
          <div className='flex space-x-8'>
            <button
              className={`p-2 mr-4 w-20 ${activeTab === 'prompt-engineering' ? 'text-blue-500' : 'text-gray-500'}`}
              onClick={() => setActiveTab('prompt-engineering')}
              style={{
                borderBottom:
                  activeTab === 'prompt-engineering'
                    ? '2px solid #3b82f6'
                    : '2px solid transparent',
                marginRight: '0.5em',
              }}
            >
              <FaBrain size={20} />
            </button>
            <button
              className={`p-2 w-20 ${activeTab === 'terminal' ? 'text-blue-500' : 'text-gray-500'}`}
              onClick={() => setActiveTab('terminal')}
              style={{
                borderBottom:
                  activeTab === 'terminal'
                    ? '2px solid #3b82f6'
                    : '2px solid transparent',
              }}
            >
              <FaTerminal size={20} />
            </button>
          </div>
          {activeTab === 'prompt-engineering' && (
            <ModelSelector
              selectedModels={selectedModels}
              setSelectedModels={setSelectedModels}
              modelConfigs={modelConfigs}
            />
          )}
          {activeTab === 'terminal' && (
            <div
              id='serverStatus'
              className='flex-grow flex-shrink w-48 m-2 flex'
            >
              <TextInput
                className='w-1/3'
                value={token}
                onChange={handleTokenChange}
                placeholder='Enter token'
              />

              {!stakzServerHealthy && (
                <div
                  className='flex items-center ml-1 relative flex-0 '
                  style={{ zIndex: 10 }}
                  onMouseLeave={() => {
                    setIsTooltipVisible(false)
                  }}
                >
                  <CiCircleAlert
                    className='p-1 w-8 h-8 fill-red-600'
                    onMouseEnter={() => setIsTooltipVisible(true)}
                  />
                  {isToolTipVisible && (
                    <ServerUnhealthy
                      style={{
                        top: tooltipPosition.top,
                        left: tooltipPosition.left,
                      }}
                    />
                  )}
                </div>
              )}
            </div>
          )}
          <div className='flex-1' />
          <ConfigSelector />
          <SplitViewSwitch />
        </div>
      </div>
      <div className='flex-grow-1 flex overflow-hidden'>
        {activeTab === 'terminal' && <Terminal />}
        {activeTab === 'prompt-engineering' && <PromptEngineeringView />}
      </div>
    </div>
  )
}
