'use client'
import React from 'react'
import { Tabs } from '@mantine/core'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import {
  currentFile as currentFileAtom,
  openFiles as openFilesAtom,
  closeFile as closeFileAtom,
  tabValue,
  tabKey,
} from '../../store/fileStore'
import { AIFile, File } from '../api/fileService'
import { AiOutlineClose } from 'react-icons/ai'
import { GoPlus } from 'react-icons/go'
import { GoDotFill } from 'react-icons/go'
import { AISubBar } from './subBar'
import { v4 as uuid } from 'uuid'

export default function HeaderSearch() {
  const [currentFile, setCurrentFile] = useAtom(currentFileAtom)
  const openFiles = useAtomValue(openFilesAtom)
  const closeFile = useSetAtom(closeFileAtom)

  // Group AI files by promptId
  const groupedAIFiles = (() => {
    const grouped = new Map<string, AIFile[]>()
    openFiles
      .filter((f): f is AIFile => f.type === 'ai')
      .forEach(file => {
        const key = file.promptId ?? uuid()
        if (!grouped.has(key)) {
          grouped.set(key, [])
        }
        grouped.get(key)?.push(file)
      })
    return grouped
  })()

  // Create a list of unique files for the main tab bar
  const uniqueTabFiles = React.useMemo(() => {
    const nonAIFiles = openFiles.filter(f => f.type !== 'ai')
    const uniqueAIFiles = Array.from(groupedAIFiles.values()).map(
      group => group[0],
    )
    return [...nonAIFiles, ...uniqueAIFiles]
  }, [openFiles, groupedAIFiles])

  const valueToFiles = (value: string) => {
    return openFiles.find(f => tabValue(f) === value)
  }

  // Get the active tab value, considering promptId for AI files
  const getActiveTabValue = () => {
    if (!currentFile) return 'new-tab'
    if (currentFile.type === 'ai') {
      // Find the representative tab for this promptId
      const aiGroup = Array.from(groupedAIFiles.values()).find(group =>
        group.some(file => file.promptId === currentFile.promptId),
      )

      return aiGroup ? tabValue(aiGroup[0]) : tabValue(currentFile)
    }
    return tabValue(currentFile)
  }

  const topTabText = (f: File) => {
    if (f.type == 'github') {
      return `${f.org}/${f.repo}`
    } else if (f.type == 'local') {
      return 'local'
    } else if (f.type == 'ai') {
      return 'AI'
    }
  }

  const topTabColor = (f: File) => {
    if (f.type == 'github') {
      return `text-purple-400`
    } else if (f.type == 'local') {
      return 'text-gray-400'
    } else if (f.type == 'ai') {
      return 'text-blue-400'
    }
  }

  const renderPath = (f: File) => {
    if (f.type == 'ai') {
      return f.path.split('/')[0]
    }
    return f.path
  }

  return (
    <div className='flex flex-col'>
      <div className='flex justify-between flex-shrink-0 flex-grow-0'>
        <Tabs
          value={getActiveTabValue()}
          className='overflow-x-scroll'
          onChange={value => {
            if (value === 'new-tab') {
              setCurrentFile(undefined)
            } else {
              const file = valueToFiles(value ?? '')
              if (file) {
                setCurrentFile(file)
              }
            }
          }}
        >
          <Tabs.List className='!flex-nowrap md:!flex-wrap'>
            {/* New tab button */}
            <Tabs.Tab key='new-tab' value='new-tab' className='m-2'>
              <div className='flex items-center'>
                <GoPlus size={20} />
              </div>
            </Tabs.Tab>

            {/* Other tabs */}
            {uniqueTabFiles.map(f => (
              <Tabs.Tab key={tabKey(f)} value={tabValue(f)} className='m-2'>
                <div className='flex items-center'>
                  <div className='flex-col items-center mr-1'>
                    <div className={`text-xs ${topTabColor(f)}`}>
                      {topTabText(f)}
                    </div>
                    <div>{renderPath(f)}</div>
                  </div>
                  {f.type == 'local' && !f.saved && <GoDotFill />}
                  <AiOutlineClose
                    size={20}
                    className='ml-2 cursor-pointer outline-2 hover:bg-neutral-800 rounded-full p-1'
                    onClick={e => {
                      e.stopPropagation()
                      closeFile(f)
                    }}
                  />
                </div>
              </Tabs.Tab>
            ))}
          </Tabs.List>
        </Tabs>
      </div>
      {currentFile?.type === 'ai' && (
        <AISubBar promptId={currentFile.promptId ?? ''} />
      )}
    </div>
  )
}
